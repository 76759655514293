/* Text */
h1 {
  font-family: "Raleway";
  color: #1b252e;
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: 700;
}

h2 {
  font-family: "Raleway";
  color: #1b252e;
  font-weight: 400;
  font-size: 3em;
  text-transform: capitalize;
  margin-bottom: 25px;
}

h3 {
  font-family: "Raleway";
  font-weight: 400;
  font-size: 2em;
  color: #fbb921;
  margin-top: 25px;
  margin-bottom: 25px;
}

p {
  font-family: "Raleway";
  font-weight: 400;
}

i {
  font-family: "Raleway";
  font-weight: 300;
  font-style: italic;
}

b {
  font-family: "Raleway";
  font-weight: bold;
}

ul {
  list-style-type: disc;
  fill: black;
  font-family: "Raleway";
  font-weight: 400;
}

span {
  font-family: "Raleway";
}

.txtalign-left {
  text-align: start;
}

/* Navbar */

.my-brand {
  color: white;
  font-size: 2.5rem;
  font-family: "Raleway";
  font-weight: bold;
  transition: color 0.3s;
}

.my-brand .brand-letter {
  color: #fbb921;
}

.my-brand:hover .brand-letter,
.my-brand:active .brand-letter {
  color: #fff; /* Change 'a' to white on hover or click */
}

.my-brand:hover,
.my-brand:active {
  color: #fbb921; /* Text color on hover or click */
}

.navbar-bgcolor {
  background-color: #1b252e;
}

.navbar-nav {
  margin-left: 20px;
}

.nav-link {
  margin-left: 20px;
  font-family: "Raleway";
  font-weight: 500;
}

.nav-item dropdown {
  margin-left: 80px;
}

/* Generic body */

.page-content {
  margin: 40px auto 50px;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .page-content {
    margin-left: 18%;
    margin-right: 18%;
  }
}

/* Footer */

.footer {
  margin-left: 350px;
  margin-right: 350px;
  text-align: center;
}

.social-icons {
  margin-right: 10px;
  display: inline-flex;
  font-size: 25px;
}

.footer-text {
  display: inline-block;
}

.quick-contact {
  font-size: 15px;
  margin-right: 10px;
  color: #fff;
}

.copyright-txt {
  color: #fff;
  margin-bottom: 0;
}

.footer-bgcolor {
  background-color: #1b252e;
}

/* Hover Icon */

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  transition: color 0.3s ease-in-out;
}

.white {
  color: white;
}

.yellow {
  color: #fbb921;
}

/* Home Page */

.center-imgtext {
  position: absolute;
  top: 43%;
  left: 0%;
  transform: translate(-0%, -50%);
  text-align: center; /* Center-align text */
  width: 100%;
  font-size: 16px;
}

.homeimg-size {
  height: 85vh;
  width: 100%;
  object-fit: cover;
}

.homecarousel-size {
  margin-bottom: 60px;
}

.scroll-button {
  background: none;
  fill: #fbb921;
  border: none;
  cursor: pointer;
  padding: 0;
}

.scroll-button:hover {
  background: none;
  fill: #1b252e;
  border: none;
  padding: 0;
}

.scroll-button.selected {
  background: none;
  fill: #1b252e;
  border: none;
  padding: 0;
}

.homecard-margin {
  margin-top: 60px;
}

.homecardbg-txt {
  background-color: #eeeeee;
  padding-left: 50px;
  padding-right: 50px;
  color: #2b2b2b;
}

.homepage-button {
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 0;
  font-family: "Raleway";
  color: #fbb921;
  border-color: #fbb921;
  background-color: #eeeeee;
  transition: background-color 0.3s, color 0.3s;
}

.homepage-button:hover {
  background-color: #fbb921;
  color: #2b2b2b;
  border-color: #fbb921;
}

.homepage-button:active {
  background-color: #fbb921;
  color: #2b2b2b;
  border-color: #fbb921;
}

.homecontent-img {
  background-color: #dddddd;
  width: 100%;
  min-height: 300px;
  max-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.bg-clr {
  background-color: #dddddd;
}

/* About Page */

.aboutcontent-img {
  background-color: #dddddd;
  width: 100%;
  min-height: 300px;
  max-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.aboutcontent-margin {
  margin-top: 60px;
}

.aboutcontentbg-txt {
  background-color: #eeeeee;
  padding-left: 50px;
  padding-right: 50px;
  color: #2b2b2b;
  padding-bottom: 30px;
}

/* Projects Page */

.projectscarousel-size {
  height: 85vh; /* Adjust as needed */
  max-width: 100%;
  margin-top: 40px;
}

.projectsimg-size {
  height: 85vh; /* Adjust as needed */
  width: 100%;
  background-color: #dddddd;
  object-fit: contain;
}

.project-thumbnail-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.project-thumbnail-container {
  display: inline-block; /* Use flexbox */
  justify-content: center; /* Center the thumbnails horizontally */
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 100%;
}

.project-thumbnail {
  width: 80px; /* Adjust the width of the thumbnails */
  background-color: #dddddd;
  height: 80px; /* Maintain aspect ratio */
  margin: 0 2px; /* Add spacing between thumbnails */
  cursor: pointer; /* Show pointer cursor on hover */
  opacity: 0.7;
}

.project-thumbnail.active {
  opacity: 1;
  border: 1px solid #fbb921;
}

/* Customer Services Page */

.centered-image-container {
  display: flex;
  justify-content: center;
}

.customerservices-img {
  max-width: 100%;
  height: auto;
}

.contact-button {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: "Raleway";
  color: #2b2b2b;
  background-color: #fbb921;
  border-color: #fbb921;
  transition: background-color 0.3s;
}

.contact-button:hover,
.contact-button:active {
  background-color: #1b252e;
  border-color: #1b252e;
}

.contactform-text {
  font-family: 'Raleway';
}

/* Content Form Alert */

.alertCF-title {
  border-bottom: none;
  justify-content: center;
}

.alertCF-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Raleway";
  font-weight: 400;
}

.alertCF-button {
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  font-family: "Raleway";
  color: #2b2b2b;
  background-color: #fbb921;
  border-color: #fbb921;
  transition: background-color 0.3s;
}

.alertCF-button:hover,
.alertCF-button:active {
  background-color: #1b252e;
  border-color: #1b252e;
}

.alertIcon-check {
  color: #5D9C59;
  font-size: 120px;
}

.alertIcon-exclaim {
  color: #DF2E38;
  font-size: 120px;
}

/* Product Card */

.ptitle-name {
  font-family: "Raleway";
  font-weight: 500;
  text-transform: capitalize;
  color: #1b252e;
  font-size: 1.5rem;
  text-align: left;
}

.ptitle-hover {
  color: #fbb921;
}

.card-link {
  text-decoration: none;
}

.pcard-bg {
  background-color: white;
  height: 450px;
  border: none;
}

.product-img {
  background-color: #dddddd;
  height: 350px;
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-radius: 1%;
}

.img-hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Product Page */

.bold-paragraph {
  font-weight: 700;
}

.productPage-img {
  margin-bottom: 20px;
  background-color: #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productPageAccord-bg {
  background-color: white;
}

.productPage-caption {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  font-weight: 500;
  font-size: 0.8em;
}

.spec-button {
  font-family: "Raleway";
  color: #2b2b2b;
  background-color: #fbb921;
  border-color: #fbb921;
  transition: background-color 0.3s;
}

.spec-button:hover,
.spec-button:active {
  background-color: #1b252e;
  border-color: #1b252e;
}

.product-thumbnail-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.product-thumbnail-container {
  display: inline-block;
  margin-bottom: 20px;
}

.product-thumbnail {
  width: 80px; /* Adjust the width of the thumbnails as needed */
  background-color: #dddddd;
  height: auto;
  margin: 0 2px; /* Adjust the margin between thumbnails as needed */
  cursor: pointer;
  opacity: 0.9;
}

.product-thumbnail.active {
  opacity: 1;
  border: 1px solid #fbb921;
}

.situ-img {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 85vh;
}

/* Accordians */

.accordion-button:not(.collapsed) {
  font-family: "Raleway";
  color: #2b2b2b;
  background-color: white;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-header .accordion-button {
  font-family: "Raleway";
  font-size: 0.4em;
  font-weight: 700;
}
